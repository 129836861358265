// see https://www.drupal.org/docs/7/api/javascript-api/managing-javascript-in-drupal-7
(function ($) {
  Drupal.behaviors.slickCarouselHP1 = { // radioatelier vltava
    attach: function (context, settings) {
      $('<div class="slick-arrows"></div>').appendTo(".pane-radiocustica-hp-b-004b-2");
      $('.pane-radiocustica-hp-b-004b-2 .view-content').slick({
        dots: false,
        autoplay: true,
        autoplaySpeed: 106000,
        speed: 600,
        slidesToShow: 1,
        mobileFirst: true,
        //prevArrow: '<span>&lt;</span>',
        //nextArrow: '<span>&gt;</span>',
        prevArrow: '<span class="prevArrow"></span><span class="pipe"></span>',
        nextArrow: '<span class="nextArrow"></span>',
        appendArrows: '.pane-radiocustica-hp-b-004b-2 .slick-arrows'
      });
    }
  };
  Drupal.behaviors.slickCarouselHP2 = { // projekt pripravuji
    attach: function (context, settings) {
      //$('<div class="slick-arrows"> | </div>').appendTo(".pane-radiocustica-hp-b-002b-2");
      $('.pane-radiocustica-hp-panel-pane-1 .view-content').slick({
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        slidesPerRow: 3,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 640,
            settings: "unslick"
          },
        ]
      });
    }
  };
  Drupal.behaviors.slickCarouselCDkompilace = {
    attach: function (context, settings) {
      $('.pane-radiocustica-cd-kompilace-b-008f .view-content').slick({
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        slidesPerRow: 3,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 639,
            settings: "unslick"
          },
        ]
      });
    }
  };
  Drupal.behaviors.slickCarouselAlbum = {
    attach: function (context, settings) {
      $('body.node-type-album .field-name-field-image-photos .field-items').slick({
        dots: false,
        lazyLoad: 'ondemand',
        /*responsive: [
          {
            breakpoint: 639,
            settings: "unslick"
          },
        ]*/
      });
    }
  };
  Drupal.behaviors.wrapTitle = {
    attach: function(context, settings) {
      $(".custom_hp-b_002b-wrapper .child2 a:contains(':')").html(function(_, html) {
        return  html.replace(/(\:)/g, '$1<br />');
      });
    }
  };
  Drupal.behaviors.scrollTriggerLogo = {
    attach: function(context, settings) {
      if ($('body.not-front').length > 0) {  // or extend to not change only at front and if no promobox
        //var top = $('#header').offset().top+30;
        //var top = $('#logo').scrollTop();
        var top = 50;
        console.log(top);
        if ($('main').length > 0) { // main exists
          $(window).scroll(function() {
            if ($(this).scrollTop() >= top) {
              $('body').addClass('scrolled');
              //$('#logo strong').animate({'background-position-y': '100%'});
            }
            else {
              $('body').removeClass('scrolled');
              //$('#logo strong').animate({'background-position-y': '0%'});
            }
          });
          $(window).resize(function() {
            top = 50;
          });
        }
      }
    }
  };
  Drupal.behaviors.scrollTriggerMenuHP = {
    attach: function(context, settings) {
      var elements = $('#header, #block-menu-menu-main-menu-radiocustica, #block-menu-menu-main-menu-radiocustica--2');
      Stickyfill.add(elements);
    }
  };
  Drupal.behaviors.responsivemenublock = {
    attach: function (context, settings) {
      $('#responsive-toggler, #closebtn').click(function() {
        $('body').toggleClass('nav-responsive-on');
        // Deactivate the default behavior of going to the next page on click
        return false;
      });
    }
  };
  Drupal.behaviors.programMenu = {
    attach: function (context, settings) {
      $('.view-display-id-on_air_site_space .views-field-title, .close-program-nav').click(function() {
          $('.view-display-id-on_air_site_space').toggleClass('program-nav-open');

          var rowClass = $(this).parent().attr('class').split(' ').pop();
          // walk rows and delete all previously added .row-nav-open except the actually clicked
          $('.view-display-id-on_air_site_space .attachment .views-row').each(function() {
            // switching between programs
            if ($(this).hasClass('row-nav-open') && $(this).attr('class').split(' ').indexOf(rowClass) < 0) {
              $(this).removeClass('row-nav-open');
              $('.view-display-id-on_air_site_space').toggleClass('program-nav-open');
            }
          });
          // same for clickable tabs
          $('.view-display-id-on_air_site_space > .view-content .views-row .views-field-title').each(function() {
            // switching between tabs
            if ($(this).hasClass('row-active') && $(this).parent().attr('class').split(' ').indexOf(rowClass) < 0) {
              $(this).removeClass('row-active');
              //$('.view-display-id-on_air_site_space').toggleClass('program-nav-open');
            }
          });

          $('.view-display-id-on_air_site_space .attachment .'+rowClass).toggleClass('row-nav-open');
          $('.view-display-id-on_air_site_space > .view-content  .'+rowClass+' .views-field-title').toggleClass('row-active');
          return false;

      });
      $('.view-display-id-on_air_site_space .show-program-default').click(function() {
        $('.view-display-id-on_air_site_space').toggleClass('program-nav-open');
        $('.view-display-id-on_air_site_space > .view-content .views-row-1 .views-field-title').toggleClass('row-active');
        $('.view-display-id-on_air_site_space .attachment .views-row-1').toggleClass('row-nav-open');
        return false;
      });
    }
  };
  Drupal.behaviors.infiniteClearfix = { // radioatelier vltava
    attach: function (context, settings) {
      var divide = $(".pane-radiocustica-aktuality-b-004f .views-row-6");
      if (divide.next().length) {
        divide.after('<div class="js-divider"></div>');
      }
      var rows = $(".pane-radiocustica-aktuality-b-004f .views-row");
      for(var i = 0; i < rows.length; i+=6) {
        rows.slice(i, i+6).wrapAll("<div class='js-wrapper'></div>");
      }
    }
  };
}(jQuery));

// second method - besides DrupalBehavior
(function ($) {

})(jQuery);
